.modal {
    @include flexCenter;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(calc(-50% - 0.5px));
    box-sizing: border-box;

    max-width: calc(100% - 40px);
    max-height: calc(100% - 40px);
    .modal-dialog {
        min-width: 35vw;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    }
    .modal-body {
        overflow: auto;
        max-height: calc(100vh - 215px);
    }

    .fade.in {
        opacity: 1;
    }

    .modal-header {
        @include flexCenter;

        button:focus {
            outline: none;
        }

        button.close {
            color: $errorRed;
            opacity: 1;

            &:hover,
            &:active,
            &:focus {
                color: darken($errorRed, 20%);
            }
        }
    }

    .modal-btn-container {
        button {
            &:not(:first-of-type) {
                margin-left: 1vw;
            }

            &:not(:last-of-type) {
                margin-right: 1vmin;
            }
        }
    }
    .audit-not-found {
        text-align: center;

        i {
            font-size: 50px;
            color: lightcoral;
            display: block;
            margin: 40px 0;
        }

        h3 {
            display: block;
            margin-bottom: 40px;
        }
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;

        &.device-audit {
            max-width: 1250px;
            width: 1250px;
        }
        &.device-config {
            max-width: 700px;
            width: 700px;
        }

        &.sim-expiry {
            min-width: 300px;
            width: 400px;
        }
    }
}

/* Container to hold both date picker and buttons on the same line */
.datepicker-and-buttons-container {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    max-width: 100%;
    position: sticky;
    top: -15px;
    z-index: 10; /* Ensures it stays on top of other content */
}

/* DatePicker Horizontal Container */
.datepicker-container-horizontal {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-grow: 1;
}

/* Quick Select Container */
.quick-select-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Label Styling */
.datepicker-label,
.quick-select-label {
    font-size: 16px;
    font-weight: 600;
    color: #333;
    white-space: nowrap;
}

/* DatePicker Styling */
.custom-datepicker {
    padding: 5px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #ddd;
    transition: border-color 0.2s ease;
    flex-grow: 1;
    height: 20px;
}

.custom-datepicker:hover {
    border-color: #999;
}

.custom-datepicker:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Quick Select Button Styling */
.quick-select-button {
    padding: 2px 5px;
    font-size: 14px;
    border: 1px solid #333;
    border-radius: 6px;
    background-color: #848a98;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.quick-select-button:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.quick-select-button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
