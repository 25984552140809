html,
body {
    font-family: 'Source sans pro', 'neuzeit-grotesk', 'system-ui';
    font-style: normal;
    font-size: 16px;
    color: $darkBlue;
}

h1,
h2,
h3,
h4,
h5,
h6,
span.h1,
th,
button,
.btn,
a.dropdown-item {
    font-family: 'neuzeit-grotesk', 'Source sans pro', 'system-ui';
    font-weight: 400;
    text-transform: uppercase;
}

h1 {
    margin-bottom: 100px;

    &.nav-title {
        font-size: 3.125rem;
        color: $lightGrey;
        margin: 0;
        font-weight: 900;
    }
}

th {
    font-size: 1.1rem;
}

button,
.btn {
    color: $darkBlue;
}

.font-ng {
    font-family: 'neuzeit-grotesk', 'Source sans pro', 'system-ui';
}

.font-ssp {
    font-family: 'Source sans pro', 'neuzeit-grotesk', 'system-ui';
}

.clr-green {
    color: $successGreen !important;
}

.clr-orange {
    color: $warningOrange !important;
}

.clr-red {
    color: $errorRed !important;
}

.clr-light-blue {
    color: $lightBlue !important;
}

.clr-dark-blue {
    color: $darkBlue !important;
}
