//** FILTER SIDEBAR **//
.filter-sidebar {
    position: fixed;
    top: 100px;
    left: 0;
    bottom: 0;
    height: calc(100vh - 100px);
    width: 400px;
    background: $white;
    border-right: 2px solid $lightGrey;
    z-index: 2;
    padding: 20px;
    transform: translateX(-100%);
    overflow-y: auto;
    @include transition;

    &.filter-expanded {
        transform: translateX(0);
    }

    .form-group {
        margin-bottom: 0;
        padding: 25px 0;
        border-bottom: 1px solid $lightGrey;
        position: relative;

        label {
            margin: 0;
            color: lighten($darkBlue, 10%);
        }

        .filter-control {
            padding: 15px 0 0;

            .range-validation {
                width: 100%;
                margin-top: 0.25rem;
                font-size: 80%;
                color: #dc3545;
            }
        }

        i {
            position: absolute;
            right: 0;
            top: 20px;
            @include transition;
        }

        svg {
            @include transition;
        }

        &.filter-expanded {
            i,
            svg {
                transform: rotate(180deg);
            }
        }

        &.filter-active {
            color: $errorRed;
            border-bottom: 2px solid $errorRed;
            @include transition;
        }
    }

    .clear-filter {
        color: $errorRed !important;
        position: relative;
        display: inline-block;
        margin-top: 10px;
        @include transition;

        &:hover {
            color: darken($errorRed, 20%) !important;
        }
    }

    .add-filter {
        color: $lightBlue !important;
        float: right;
        position: relative;
        margin-top: 10px;
        @include transition;

        &:hover {
            color: darken($lightBlue, 20%) !important;
        }
    }
}

.clear-links {
    color: $errorRed !important;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    margin: 0 0 0 20px;
    @include transition;

    &:hover {
        color: darken($errorRed, 20%) !important;
    }
}

.modal-dialog.device-metadatahistory {
    .modal-body {
        .table-container {
            // padding-top: 0;
            display: table;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-bottom: 0px;
        }
        .table-container.filter-expanded {
            padding-left: 394px;
        }
        .filter-sidebar.filter-expanded {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            display: table-cell;
            width: 387px;
            height: auto;
        }

        .row {
            display: table-row;
        }
    }
}

@media (min-width: 576px) {
    .modal-dialog.device-metadatahistory {
        max-width: 1250px;
        width: 1250px;
    }
}

//Media queries
@media screen and (max-width: 1200px) {
    .filter-sidebar {
        padding: 20px;
    }
}
