* {
    box-sizing: border-box;
}

html,
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

main {
    padding: 150px 100px 100px 200px;
}

.row {
    margin: 0;
}

.pointer {
    cursor: pointer;
}

.flex-center {
    @include flexCenter;
}

a {
    cursor: pointer;
}

ul.list-style-none {
    padding-left: 0;
    li {
        list-style: none;
    }
}

.is-hidden {
    display: none;
}

.is-visible {
    display: block;
}

.simCardActivation {
    font-size: 2.5rem;
    font-variant: 1;
    margin-bottom: 10px;
}

.simActiveAlert {
    margin-left: 10px;
    font-size: 20px;
    color: #04c9a9;
}
.simNotActiveAlert {
    margin-left: 10px;
    font-size: 20px;
    color: #d64541;
}

.simActiveStatus {
    margin-left: 18px;
    margin-right: 18px;
    font-size: 20px;
    color: #04c9a9;
}
.simNotActiveStatus {
    margin-left: 18px;
    margin-right: 18px;
    font-size: 20px;
    color: #d64541;
}
