.table-container {
    @include transition;
    margin-bottom: 100px;
    display: block;
    padding: 0;
    padding-top: 70px;

    &.filter-expanded {
        padding-left: 212px;
    }

    &.right-pane-expanded {
        padding-right: 315px;
    }

    table {
        font-size: 1rem;

        thead {
            background: $lightGrey;

            th {
                &:hover {
                    background: $lightGrey;
                }
            }

            td.flex-center {
                border: none;
            }

            &:hover {
                background: $lightGrey;
            }
        }

        tr {
            padding: 30px 0;
            @include transition;

            &:not(:first-of-type) {
                border-top: 1px solid $lightGrey;
            }

            &:hover {
                background-color: lighten($lightGrey, 5%);
            }

            th {
                font-size: 1rem;
                padding: 0.8rem 0.3rem;
            }

            td {
                padding: 0.3rem;
            }

            td {
                border-top: none;
                vertical-align: middle;

                &.details-panel-toggle {
                    &:hover {
                        i {
                            color: darken($brandPrimary, 10%) !important;
                            &.clr-red {
                                color: darken($errorRed, 10%) !important;
                            }
                        }
                    }
                    i {
                        @include transition;
                        font-size: 1.5rem;
                    }
                }

                &.status-alerts {
                    i {
                        font-size: 1.5rem;
                        &:not(:last-of-type) {
                            margin-right: 10px;
                        }
                    }
                }
            }

            th {
                background-color: $lightGrey;
                text-transform: uppercase;
                border: none;
                white-space: nowrap;
                vertical-align: middle;

                i {
                    margin-left: 10px;
                }

                &.sortBy {
                    cursor: pointer;
                    color: $brandPrimary;
                    @include transition;

                    i {
                        color: $brandPrimary;
                        display: inline-block;
                        transform: rotate(0deg);
                        @include transition;
                    }

                    &.sortByDescending {
                        i {
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    .select-count {
        position: relative;
        display: inline-block;
        margin: -60px 0 0 20px;
        @include transition;
    }
}

.action-buttons {
    position: absolute;
    right: 100px;
    transition: all 200ms ease-in-out;

    &.right-pane-expanded {
        padding-right: 315px;
    }

    button:not(:last-of-type) {
        margin-right: 20px;
    }
}

.device-audit {
    .table-container {
        margin-bottom: 20px;
        .table {
            td {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
        .table-controls {
            margin-top: 5 !important;
        }
    }
}

.configuration-table {
    .config-version {
        margin-bottom: 10px;
    }
    .keyName {
        margin: auto;
        padding: 10px;
        width: 200px;
        text-align: left;
    }
    .keyValue {
        input {
            height: 40px;
            text-align: left;
            float: right;
        }
        .Int16_4 {
            margin-left: 5px;
            width: 71.2px;
        }
        .Int16_4:last-of-type {
            margin-left: 0px;
        }
        float: right;
        width: 300px;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        appearance: none;
    }
}
