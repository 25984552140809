.sidebar-right {
    position: fixed;
    top: 100px;
    right: 0;
    bottom: 0;
    width: 400px;
    height: calc(100vh - 100px);
    background: #f5f7fa;
    border-left: 1px solid #edf1f5;
    padding: 40px 30px;
    z-index: 10;
    transform: translateX(100%);
    transition: all 200ms ease-in-out;

    &.right-pane-expanded {
        transform: translateX(0);
    }

    .btn {
        margin-right: 20px;
    }
}
