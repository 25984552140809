nav.sidebar-tabs {
    position: fixed;
    top: 100px;
    bottom: 0;
    left: 0;
    width: 100px;
    height: calc(100% - 100px);

    a.nav-link {
        @include flexCenter;
        flex-direction: column;
        border-radius: 0 !important;
        background: $lightGrey;
        color: $darkBlue;
        @include transition;
        font-size: 100%;
        height: calc(20vh - 20px);

        &:not(.active) {
            &:hover {
                background: lighten($lightGrey, 5%);
                color: $brandPrimary;
            }
        }

        &.active {
            background: $white;
            color: $brandPrimary;
        }

        i {
            margin-bottom: 20%;
            font-size: 2.5rem;
        }
    }
}
