//Font colours
.clr-primary {
    color: $brandPrimary !important;
}

.clr-primary-alt-state {
    &:hover,
    &:active,
    &:focus {
        color: darken($brandPrimary, 10%) !important;
    }
}

.clr-green {
    color: #04c9a9 !important;
}

.clr-green-alt-state {
    &:hover,
    &:active,
    &:focus {
        color: darken(#04c9a9, 10%) !important;
    }
}

.clr-yellow {
    color: #baae00 !important;
}

.clr-red {
    color: #d64541 !important;
}

.clr-red-alt-state {
    &:hover,
    &:active,
    &:focus {
        color: darken(#d64541, 10%) !important;
    }
}

//Background colours
.bkg-primary {
    background-color: $brandPrimary !important;
}

.bkg-primary-alt-state {
    &:hover,
    &:active,
    &:focus {
        background-color: darken($brandPrimary, 10%) !important;
    }
}

//Border colours
.brd-primary {
    border-color: $brandPrimary !important;
}

.brd-primary-alt-state {
    &:hover,
    &:active,
    &:focus {
        border-color: darken($brandPrimary, 10%) !important;
    }
}

.brd-green {
    border-color: #04c9a9 !important;
}

.brd-green-alt-state {
    &:hover,
    &:active,
    &:focus {
        border-color: darken(#04c9a9, 10%) !important;
    }
}

.brd-red {
    padding-left: 17px;
    padding-right: 17px;
    border-color: #d64541 !important;
}

.brd-red-alt-state {
    &:hover,
    &:active,
    &:focus {
        border-color: darken(#d64541, 10%) !important;
    }
}
