.dashboard {
    font-size: 20px;

    .form-inline {
        margin-bottom: 50px;
    }

    .form-group {
        margin: 0 50px 0 0;
    }

    select {
        background-color: $white;
        margin-left: 20px;
    }

    a {
        text-decoration: none;
    }

    .dashboard-title {
        font-size: 30px;
        color: $lightGrey;
        position: absolute;
        top: 0;
        left: 0;
    }

    .dashboard-section-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .dashboard-section {
        display: flex;
        width: 100%;
        overflow: visible;
        position: relative;
        padding-top: 70px;

        &:not(:last-of-type) {
            margin-bottom: 100px;
        }

        &.dashboard-section-half {
            width: 50%;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            text-align: left;
        }
    }

    .dashboard-overview-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .dashboard-overview {
        height: 175px;
        width: 175px;
        padding: 20px;
        margin: 20px 0;
        border-radius: 50%;
        background: linear-gradient($lighterBlue, $lightBlue);
        color: $white;
        text-align: center;
        flex-direction: column;
        box-shadow: 0 2px 40px rgba(179, 62, 62, 0.25);
        @include flexCenter;
        @include transition;

        &:hover {
            box-shadow: 0 2px 20px rgba(179, 62, 62, 0.5);
        }

        &:not(:last-of-type) {
            margin-right: 100px;
        }

        .dashboard-overview-title {
            font-size: 40px;
        }
    }

    .device-group-wrapper {
        width: 100%;
        max-height: 400px;
        overflow-y: auto;
    }

    .device-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        border-bottom: 2px solid $lightGrey;
        overflow: auto;
        padding: 10px 0;

        &:first-of-type {
            border-top: 2px solid $lightGrey;
        }
    }

    .device-group-link {
        text-transform: uppercase;
    }

    svg {
        @include transition;
        filter: drop-shadow(0 2px 40px rgba(0, 0, 0, 0.35));

        &:hover {
            filter: drop-shadow(0 2px 30px rgba(0, 0, 0, 0.5));
        }
        text {
            font-size: 40px;
        }
    }

    .dashboard-notification-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
    }

    .dashboard-notification {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 250px;
        position: relative;
    }

    .dashboard-notification-title {
        font-size: 40px;
        color: $lightGrey;
    }

    .dashboard-notification-text {
        color: $mediumRed;
        margin-bottom: 70px;
    }

    .device-notification-link {
        position: absolute;
        bottom: 30px;
        text-transform: uppercase;

        a {
            text-decoration: none;
        }
    }
}
