.table-controls {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;

    nav.nav-pagination {
        ul.pagination {
            border-radius: 0;
            margin: 0;
            li {
                &.disabled {
                    .page-link {
                        color: $lightGrey;
                    }
                }
                &.active {
                    .page-link {
                        z-index: 0;
                        color: $brandPrimary;
                        background-color: $lightGrey;
                        &:hover {
                            z-index: 0;
                            color: $brandPrimary;
                            background-color: $lightGrey;
                            cursor: default;
                        }
                    }
                }
                .page-link {
                    color: $darkBlue;
                    border-radius: 0;
                    border-color: $lightGrey;
                    @include transition;
                    -webkit-touch-callout: none;
                    -webkit-user-select: none;
                    -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    &:hover {
                        z-index: 0;
                        background-color: $lightGrey;
                        color: $brandPrimary;
                    }

                    &:not(:disabled) {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .table-page-size-dropdown {
        label {
            margin: 0;
        }
    }
}
