@keyframes fadeUp {
    0% {
        opacity:0;
        padding:0 50px;
        height:0;
        will-change:opacity;
    }
    50% {
        padding:50px;
        height:100%;
    }
    100% {
        opacity:1;
        padding:50px;
        will-change:unset;
    }
}

@keyframes fadeIn {
    0% {
        opacity:0;
        transform:scale(0.9)
    }

    100% {
        opacity:1;
        transform:scale(1)
    }
}

.fade-in {
    animation:fadeIn 1000ms ease 0ms forwards 1;
}

.fade-up {
    animation:fadeUp 600ms ease-in-out 0ms forwards 1;
}