.loading-indicator:before {
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: calc(100% - 109.637px);
    height: calc(100% - 100px);
    bottom: 0;
    right: 0;
    z-index: 1;
}

.loading-indicator:after {
    content: '';
    position: fixed;
    width: 100%;
    bottom: calc(50% - 109.637px);
    right: calc(50% - 100px);
    z-index: 1050;
    color: white;
    align-content: center;
    border: 16px solid $lightGrey; /* Light grey */
    border-top: 16px solid rgba(0, 0, 0, 0.3); /* Blue */
    border-radius: 50%;
    width: 8em;
    height: 8em;
    animation: loader 1.1s infinite linear;
}

@-webkit-keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
