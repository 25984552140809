$break-ipad-portrait-max: 1366px;

.btn {
    padding: 10px 20px;
    border-radius: 0;
    font-size: 1rem;
    @include transition;
    position: relative;
    background: transparent;
    border: 2px solid;
    &:hover,
    &:active,
    &:focus {
        outline: none !important;
        box-shadow: none !important;
        background: transparent;
    }

    &:disabled {
        cursor: not-allowed;
        background: transparent;
        border-color: $darkGrey;
        color: $darkGrey;

        &:hover,
        &:active,
        &:focus {
            background: transparent;
            border-color: $darkGrey;
            color: $darkGrey;
        }
    }

    i {
        font-size: 1.1rem;
        color: inherit;
    }
}

.btn-border {
    border: 2px solid;
}

.btn-default {
    border: 2px solid $darkBlue;
    color: $darkBlue;

    &:hover,
    &:active,
    &:focus {
        color: lighten($darkBlue, 20%);
        border-color: lighten($darkBlue, 20%);
    }
}

.btn-uppercase {
    text-transform: uppercase;
}
.btn-shadow {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.45);
    &:hover,
    &:active,
    &:focus {
        box-shadow: none;
    }
}

.btn-submit {
    display: block;
    width: 100%;
    font-size: 1.6rem;
    margin-top: 100px;
    text-transform: uppercase;
    font-weight: 600;
}

.btn-clear {
    background: $white;
    &:hover,
    &:active,
    &:focus {
        background: $white;
    }
}

.btn-filter {
    @include transition;
    text-transform: uppercase;

    &.filter-expanded {
        border-color: $errorRed;
        color: $errorRed;
    }

    &.filter-active {
        background-color: $brandPrimary;
        border-color: $brandPrimary;
        color: $white;
    }
}

.btn-filter-close {
    @include transition;

    &.filter-expanded {
        border-color: $errorRed;
        color: $errorRed;
    }
}

.btn-error {
    border: 2px solid $errorRed;
    color: $errorRed;
    &:hover,
    &:active,
    &:focus {
        background: transparent;
        color: darken($errorRed, 20%);
        border-color: darken($errorRed, 20%);
    }
}

.btn-warning {
    border: 2px solid $warningOrange;
    color: $warningOrange;
    &:hover,
    &:active,
    &:focus {
        background: transparent;
        color: darken($warningOrange, 20%);
        border-color: darken($warningOrange, 20%);
    }
}

.btn-success {
    border: 2px solid $successGreen;
    color: $successGreen;
    &:hover,
    &:active,
    &:focus {
        background: transparent !important;
        color: darken($successGreen, 20%) !important;
        border-color: darken($successGreen, 20%) !important;
    }
}

.btn-container {
    &.btn-container-form {
        margin-top: 50px;
    }

    a,
    button {
        margin: 15px 20px 15px 0;
    }
}

@media screen and (max-width: $break-ipad-portrait-max) {
    .btn {
        font-size: 0.6rem;
        padding: 10px;
    }
}
