.form-group {
    margin-bottom: 30px;

    label {
        text-transform: uppercase;
    }
}

input[type='text'],
input[type='password'],
select,
.form-control,
.form-control:disabled {
    border-radius: 0 !important;
    border: 1px solid $lightGrey;
    background: $lightGrey;
    @include transition;
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid $brandPrimary;
    }
}

/* Customize the label (the checkbox-container) */

.checkbox-container {
    display: block;
    position: relative;
    height: 25px;
    width: 25px;
    margin-bottom: 0;
    cursor: pointer;
    user-select: none;
    @include transition;
}

/* Hide the browser's default checkbox */

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $white;
    border: 1px solid $lightGrey;
    @include transition;
}

/* On mouse-over, add a grey background color */

.checkbox-container:hover input ~ .checkmark {
    background-color: $brandPrimary;
    border-color: $brandPrimary;
}

/* When the checkbox is checked, add a blue background */

.checkbox-container input:checked ~ .checkmark {
    background-color: $brandPrimary;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */

.checkbox-container .checkmark:after {
    font-family: 'Font Awesome 5 Pro';
    content: '\f00c';
    color: $white;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    font-size: 0.95rem;
}

select {
    text-transform: uppercase;

    option {
        background-color: $white;
    }
}

.remove-link {
    color: $errorRed !important;
    position: relative;
    display: inline-block;
    margin: 10px 0 30px;
    @include transition;

    &:hover {
        color: darken($errorRed, 20%) !important;
    }
}

.add-link {
    color: $lightBlue !important;
    position: relative;
    display: inline-block;
    margin: 10px 0 30px;
    @include transition;

    &:hover {
        color: darken($lightBlue, 20%) !important;
    }
}

.device-configuration-input {
    textarea {
        height: 225px;
    }
}

.react-datepicker-wrapper {
    display: block;
    .react-datepicker__input-container {
        display: block;
    }
}
