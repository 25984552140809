nav.navbar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 70px;
    border-bottom: 2px solid $lightGrey;
    padding: 0px 30px;
    background-color: $white;
    z-index: 2;
    .navbar-actions {
        position: relative;
        height: 70px;
        display: flex;
        align-items: center;
        .dropdown {
            &:active,
            &:focus {
                outline: none !important;
            }
            &.show {
                button {
                    color: $brandPrimary;
                }
            }
            button {
                border-radius: 0;
                outline: none !important;
                outline-color: transparent !important;
                background: transparent;
                @include transition;
                &:active,
                &:focus {
                    outline: none !important;
                    outline-color: transparent !important;
                }
                i {
                    margin-right: 10px;
                }
                &:after {
                    margin-left: 0.5rem;
                }
            }
            .dropdown-menu {
                padding: 0;
                border-radius: 0;
                top: 155%;
                border-color: $lightGrey;

                a {
                    padding: 15px;
                    i {
                        margin-right: 10px;
                    }
                    &:hover {
                        color: $brandPrimary;
                        @include transition;
                    }
                }
            }
        }
    }
}
