.table-details-panel {
    height: auto;
    background: transparent;
    border-right: 4px solid;
    border-bottom: 2px solid;
    border-left: 3px solid;
    border-color: $lightGrey;
    height: auto;
    td {
        padding: 0 !important;
        .device-details {
            width: 100%;
            flex-direction: column;
            padding: 0 20px;
            .device-details-upper {
                width: 100%;
                padding: 0 0 50px;
                @include flexCenter(space-between, center);
                border-bottom: 2px solid $lightGrey;
                .device-status {
                    font-size: 1rem;
                    .status-item {
                        @include flexCenter(space-between, center);
                        float: left;
                        margin-right: 30px; //Design decision to be made on the below commented code
                        // &.last-used-status {
                        //     display: block;
                        //     clear: both;
                        //     margin-top: 30px;
                        // }
                    }
                    i {
                        font-size: 1.2rem;
                        margin-right: 5px;
                        color: inherit;
                    }
                }

                .status-alerts {
                    display: flex;
                    flex-direction: column;

                    div {
                        display: flex;
                        align-items: center;
                        &:not(:last-of-type) {
                            margin-bottom: 10px;
                        }

                        i {
                            font-size: 1.5rem;
                            width: 50px;
                            text-align: center;
                        }
                    }
                }
            }

            .device-details-lower {
                display: flex;
                padding: 50px 0 0;

                .device-info {
                    flex-grow: 1;

                    .device-info-list {
                        display: inline-block;
                        margin-right: 30px;
                        flex-grow: 1;
                        width: 45%;

                        table {
                            font-size: 1rem;
                            word-wrap: break-word;
                            table-layout: fixed;

                            tr {
                                td {
                                    padding: 15px 0 !important;
                                    &:nth-of-type(odd) {
                                        font-weight: 100;
                                    }
                                    &:nth-of-type(even) {
                                        padding-left: 20px !important;
                                    }
                                }
                                &:hover {
                                    background: $white;
                                }
                            }
                        }
                    }
                }

                .device-actions {
                    flex: 0 0 155px;
                    text-align: right;

                    a,
                    button {
                        display: inline-block;
                        width: 170px;
                        margin: 0 0 15px 0;
                    }
                }
            }
        }
    }
    &:hover {
        background: transparent !important;
    }
    .table-details-panel-inner {
        width: 100%;
        height: 100%;
        padding: 50px;
        @include flexCenter;
        flex-direction: column;
    }
}

//When the details panel is open, the parent row
tr {
    &.details-panel-open {
        background: $lightGrey;
        color: $brandPrimary;
        &:hover {
            background: $lightGrey !important;
        }
    }
    &:first-of-type {
        border-top: 2px solid transparent;
        &.details-panel-open {
            border-top: 2px solid $white;
        }
    }
}
