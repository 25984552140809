//Standard colours
$white: #ffffff;
$black: #000000;
$lightGrey: #eaeaea;
$darkGrey: #252525; // rgb(37,37,37)

$lighterBlue: #72edf2;
$lightBlue: #52b3d9;
$darkBlue: #22313f;

$lightRed: #f05f57;
$mediumRed: #ec644b;

//State colours
$errorRed: #d64541;
$warningOrange: #f89406;
$successGreen: #03c9a9;

//Brand colours
$brandPrimary: $lightBlue;

:export {
    successGreen: $successGreen;
    red: $errorRed;
}
